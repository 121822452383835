import { useState } from 'react';

export const useRecharge = () => {

    const [submittedRecharge, setSubmittedRecharge] = useState(null);
    const [selectedRecharge, setSelectedRecharge] = useState(null);

    const submitRecharge = async (userToken, curUsername, rechargeAmount, method) => {

        const response = await fetch('https://api.medichip.health/recharge/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({
                username: curUsername,
                recharge_amount: rechargeAmount,
                payment_method: method
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log("Failed.");
        }
        if (response.ok) {
            console.log("Success.", json);
            setSubmittedRecharge(json);
            setSelectedRecharge(json);
        }

    }

    const approveRecharge = async (authToken, rechargeData, adminUsername, userData, addRemark) => {

        if (rechargeData.status == "Completed") {
            return;
        } else {
            const updateUserBal = await fetch('https://api.medichip.health/user/'+userData._id, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    cashWallet: Number(userData.cashWallet)+Number(rechargeData.recharge_amount)
                })
            })
            const result = await updateUserBal.json();
            if (!updateUserBal.ok) {
                console.log("Failed update user wallet.");
            }
            if (updateUserBal.ok) {
                console.log("Success update user cash wallet", result);
                const response = await fetch('https://api.medichip.health/recharge/'+rechargeData._id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                    body: JSON.stringify({
                        status: "Completed",
                        balance_before: Number(userData.cashWallet),
                        balance_after: Number(userData.cashWallet)+Number(rechargeData.recharge_amount),
                        approval: adminUsername,
                        remark: addRemark
                    })
                })
                const json = await response.json();
                if (!response.ok) {
                    console.log("Failed.");
                }
                if (response.ok) {
                    console.log("Success Approval.", json);
                }
            }
        }

    }

    const rejectRecharge = async (authToken, rechargeData, adminUsername, addRemark) => {

        const response = await fetch('https://api.medichip.health/recharge/'+rechargeData._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                status: "Cancelled",
                approval: adminUsername,
                remark: addRemark
            })
        })
        const json = await response.json();
        if (response.ok) {
            console.log("Recharge Cancelled", json);
            // setSubmittedRecharge(json);
            // setSelectedRecharge(json);
        }

    }

    return { submitRecharge, submittedRecharge, selectedRecharge, setSelectedRecharge, approveRecharge, rejectRecharge };
}
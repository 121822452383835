import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../hooks/useOrder';
import { useAuthContext } from '../hooks/useAuthContext';

const Appointments = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [submitted, setSubmitted] = useState(false);

	const [userData, setUserData] = useState();
	const fetchUserData = async (selectedUsername) => {
		const response = await fetch('https://api.medichip.health/user/' + selectedUsername, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setUserData(json);
		console.log("fetch",json);
	}

	const [appointmentData, setAppointmentData] = useState([]);
	const fetchOrdersData = async () => {
		const response = await fetch('https://api.medichip.health/appointment/all', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setAppointmentData(json);
	}

	const createTransaction = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateOrderStatus(admin.token, admin.username, selectedOrder, statusOption, userData);
		fetchOrdersData();
		setSubmitted(true);
	};

	useEffect(() => {
		fetchOrdersData();
	}, []);

  	return (
		<>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Appointments</h3>
						<p className="mt-2 mb-0">Total {appointmentData && appointmentData.length} records</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Appointment</p></th>
									<th className="p-3" scope="col"><p className="mb-0">User</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Device</p></th>
									<th className="p-3" scope="col"><p className="mb-0">PV</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Remark</p></th>
								</tr>
							</thead>
							<tbody>
							{appointmentData && appointmentData.map((appointment, index) => (
								<tr key={index}>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}><strong>ID:</strong> {appointment._id}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Branch:</strong> {appointment.branch}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Date:</strong> {Moment(appointment.day).format('YYYY/MM/DD')}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Time Slot:</strong> {appointment.time}H</p>
										{appointment.status == "Completed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="profit-green">{appointment.status}</span></strong></p> : <></>}
										{appointment.status == "Appointment Confirmed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">{appointment.status}</span></strong></p> : <></>}
										{appointment.status == "Cancelled" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="error">{appointment.status}</span></strong></p> : <></>}
									</td>
									<td className="px-3 py-3">
										<p className="mb-0">{appointment.username}</p>
									</td>
									<td className="px-3 py-3">
										<p className="mb-0">{appointment.unit}</p>
										{appointment.status == "Appointment Confirmed" ? (
											<button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#transactionModal" onClick={(e) => {
											}}>
												<p className="mb-0" style={{fontSize:"14px"}}>Control</p>
											</button>
										):(
											<></>
										)}
									</td>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Created:</strong> {appointment.create_by}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>On Hold:</strong> {parseFloat(appointment.points_onhold).toFixed(2)}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Spent:</strong> {parseFloat(appointment.points_spent).toFixed(2)}</p>
									</td>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}>{appointment.remark != "" ? appointment.remark : "-"}</p>
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Appointments;
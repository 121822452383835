import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useCreateTransaction } from '../hooks/useCreateTransaction';
import { useOrder } from '../hooks/useOrder';
import { useWithdrawal } from '../hooks/useWithdrawal';

const SearchUser = () => {

	const { admin } = useAuthContext();
  	const { topUpCredit, deductCredit, setScoring, editPassword, setVolume, isLoading, error, actionSuccess, setActionSuccess } = useCreateTransaction();
	const { updateOrderStatus } = useOrder();
	const { updateWithdrawalStatus, updateRemarks } = useWithdrawal();

  	const [selectedUser, setSelectedUser] = useState(null);
  	const [txnType, setTxnType] = useState(0);
  	const [amount, setAmount] = useState(0);
	const [userScore, setUserScore] = useState(0);
	const [username, setUsername] = useState("");

	const [submitted, setSubmitted] = useState(false);

  	const createTransaction = async (e) => {
		e.preventDefault();
		if (txnType == 1) {
			var balanceAfter = Number(selectedUser.cashWallet) + Number(amount);
			await topUpCredit(admin.token, selectedUser._id, selectedUser.username, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUserDataByUsername();
		} else if (txnType == 2) {
			var balanceAfter = Number(selectedUser.cashWallet) - Number(amount);
			await deductCredit(admin.token, selectedUser._id, selectedUser.username, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUserDataByUsername();
		}
	};

	const setUserScoring = async (e) => {
		e.preventDefault();
		await setScoring(admin.token, selectedUser._id, selectedUser.points, userScore);
		fetchUserDataByUsername();
	};

	const [userMatchVolume, setUserMatchVolume] = useState(0);
	const setMatchVolume = async (e) => {
		e.preventDefault();
		if (selectedUser.matchVolume == null ) {
			await setVolume(admin.token, selectedUser._id, 0, userMatchVolume);
		} else {
			await setVolume(admin.token, selectedUser._id, selectedUser.matchVolume, userMatchVolume);
		}
		fetchUserDataByUsername();
	};

	const [newPassword, setNewPassword] = useState('');
	const changePassword = async (e) => {
		e.preventDefault();
		await editPassword(admin.token, selectedUser._id, newPassword);
	};

	const updateOrder = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateOrderStatus(admin.token, admin.username, selectedOrder, statusOption, userData, commAmount);
		// fetchOrdersData();
		setSubmitted(true);
	};

	const adminUpdateStatus = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateWithdrawalStatus(admin.token, admin.username, selectedWithdrawal.username, selectedWithdrawal._id, selectedWithdrawal.amount, statusOption, adminRemarks);
		// getAllWithdrawalsByUser();
		setSubmitted(true);
	};

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [userData, setUserData] = useState(null);
	const fetchUserDataByUsername = async () => {
		const response = await fetch('https://api.medichip.health/user/' + username, {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
		}
	}

	const [appointmentData, setAppointmentData] = useState([]);
	const fetchAppointmentData = async () => {
		const response = await fetch('https://api.medichip.health/appointment/user/' + username, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setAppointmentData(json);
	}

	const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
	const [adminRemarks, setAdminRemarks] = useState("");


  	return (
		<>
			{/* Create Transaction Modal */}
			<div className="modal fade" id="transactionModal" tabindex="-1" role="dialog" aria-labelledby="transaction" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Create Transaction</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser !== null ? (
									<form className="w-100" onSubmit={createTransaction}>
									<div className="mb-2">
										<label htmlFor="txnType">
										<strong>Transaction Type:</strong>
										</label>
										<select 
										name='txnType'
										className='form-control'
										onChange={(e) => setTxnType(e.target.value)}
										>
										<option value="0">Choose Transaction</option>
										<option value="1">Top Up Credit</option>
										<option value="2">Deduct Credit</option>
										</select>
									</div>
									<div className="mb-2">
										<label htmlFor="amount">
										<strong>Amount:</strong>
										</label>
										<input
										type="number"
										step="0.01"
										placeholder='Enter transaction amount'
										autoComplete='off'
										name='amount'
										className='form-control'
										onChange={(e) => setAmount(e.target.value)}
										/>
									</div>
									{txnType == 0 ? (
										<></>
									):(
										<>
										<div className="mt-3">
										<label><strong>Confirm Transaction:</strong></label>
										{txnType == 1 ? <p className="">Top Up <strong><span className="profit-green">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										{txnType == 2 ? <p className="">Deduct <strong><span className="error">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										</div>
										<button disabled={isLoading} type="submit" className="btn setting-button w-100">
										<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
										</>
									)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Transaction Success</h5>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>

			{/* Change Password */}
			<div className="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePassword" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser != null ? (
									<form className="w-100" onSubmit={changePassword}>
										<div className="mb-2">
											<label htmlFor="newPW">
												<strong>New Password</strong>
											</label>
											<input
												type="password"
												placeholder='Enter new password'
												autoComplete='off'
												name='newPW'
												className='form-control'
												onChange={(e) => setNewPassword(e.target.value)}
											/>
										</div>
										<button disabled={isLoading} type="submit" className="btn setting-button w-100">
											<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Password Change Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Update Order Status */}
			<div className="modal fade" id="updateOrderStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateOrderStatus" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Update Order Status</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!submitted ? (
							<div className="modal-body">
								{selectedOrder !== null ? (
									<form className="w-100" onSubmit={updateOrder}>
										<h6 className="mb-0">Order ID:</h6>
										<p>#{selectedOrder.orderID}</p>
										<h6 className="mb-0">Username:</h6>
										<p>{selectedOrder.username}</p>
										<h6 className="mb-0">Order Amount:</h6>
										<p>{parseFloat(selectedOrder.price).toFixed(2)}</p>
										<h6 className="mb-0">Total Amount:</h6>
										<p>{parseFloat(selectedOrder.totalAmount).toFixed(2)}</p>
										<div className="mb-2">
											<label>
											<strong>Update Status:</strong>
											</label>
											<select 
											className='form-control'
											onChange={(e) => setStatusOption(e.target.value)}
											>
												<option value="">Choose Status</option>
												<option value="Matching">Matching</option>
												<option value="Matched">Matched</option>
												<option value="Error">Error</option>
												<option value="Cancelled">Cancelled</option>
											</select>
										</div>
										{statusOption === "Matched" ? (
											<div className="mb-2">
												<label htmlFor="score">
												<strong>Bonus / Commission:</strong>
												</label>
												<input
													type="number"
													step="0.01"
													placeholder='Enter commission amount'
													autoComplete='off'
													name='commission'
													className='form-control'
													onChange={(e) => setCommAmount(e.target.value)}
												/>
											</div>
										):(
											<></>
										)}
										{statusOption === "Matching" || statusOption === "Matched" || statusOption === "Cancelled" ? (
											<button type="submit" className="btn setting-button w-100 mt-3">
												<p className="text-white mb-0">Confirm</p>
											</button>
										):(
											<></>
										)}
									</form>
								):(
									<p className="error mb-0">Failed to fetch order data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Order Status Updated</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			
			{/* Update Withdrawal Status */}
			<div className="modal fade" id="updateWithdrawalStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateWithdrawalStatus" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Withdrawal Status</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!submitted ? (
							<div className="modal-body">
								{selectedWithdrawal !== null ? (

									<form className="w-100" onSubmit={adminUpdateStatus}>
										<h6 className="mb-0">Username:</h6>
										<p>{selectedWithdrawal.username}</p>
										<h6 className="mb-0">Withdrawal ID:</h6>
										<p>#{selectedWithdrawal.withdrawID}</p>
										<h6 className="mb-0">Withdrawal Amount:</h6>
										<p>{parseFloat(selectedWithdrawal.amount).toFixed(2)}</p>
										<h6 className="mb-0">Current Status:</h6>
										{selectedWithdrawal.status === "Processing" ? <p className="vip-yellow">{selectedWithdrawal.status}</p> : null}
										{selectedWithdrawal.status === "Approved" ? <p className="profit-green">{selectedWithdrawal.status}</p> : null}
										{selectedWithdrawal.status === "Rejected" ? <p className="error">{selectedWithdrawal.status}</p> : null}
										<div className="mb-2">
											<label>
											<strong>Update Status:</strong>
											</label>
											<select 
											className='form-control'
											onChange={(e) => setStatusOption(e.target.value)}
											>
												<option value="">Choose Status</option>
												<option value="Approved">Approved</option>
												<option value="Rejected">Rejected</option>
											</select>
										</div>
										<div className="mb-2">
											<label>
											<strong>Remarks:</strong>
											</label>
											<textarea
											name='review'
											className='w-100 py-3 review-text-field'
											placeholder='Write your review here...'
											onChange={(e) => setAdminRemarks(e.target.value)}
											>
											</textarea>
										</div>
										{statusOption === "Processing" || statusOption === "Approved" || statusOption === "Rejected" ? (
											<button type="submit" className="btn setting-button w-100 mt-3">
												<p className="text-white mb-0">Confirm</p>
											</button>
										):(
											<></>
										)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch order data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Withdrawal Status Updated</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			
			{/* Search and Filter */}
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Search User</h3>
					</div>
					<div className="col-12 mt-3">
						<div className="page-container">
							<div className="mb-2">
								<label htmlFor="username">Username</label>
								<input
								type="text"
								placeholder='Enter Username'
								autoComplete='off'
								name='username'
								className='form-control'
								onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<button className="btn setting-button w-100 mt-3" onClick={(e) => {
								fetchUserDataByUsername();
								fetchAppointmentData();
							}}>
								<p className="text-white mb-0">Search User</p>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					{userData !== null && userData.length == 1 ? (
						<>
							<div className="col-12 mt-3">
								<h5 className="mt-3 mb-0">User Profile Data</h5>
							</div>
							<div className="col-12 mt-3">
								<table className="table table-responsive table-hover table-striped">
									<thead className="thead-dark">
										<tr className="">
											<th className="p-3" scope="col"><p className="mb-0">KYC</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Username</p></th>
											<th className="p-3" scope="col"><p className="mb-0">PV</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Comm</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Bank</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Full Name</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Phone</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Email</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Registered</p></th>
										</tr>
									</thead>
									<tbody>
										{userData && userData.map((user, index) => (
											<tr key={index}>
												<td className="px-3 py-3">
													{user.kyc ? (
														<p className="mb-0"><strong className="profit-green">Completed</strong></p>
													):(
														<p className="mb-0"><strong className="error">Pending</strong></p>
													)}

												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{user.username}</p>
													<p className="mb-0"><strong>Rank:</strong> M{user.rank}</p>
													<p className="mb-0"><strong>Referrer:</strong> {user.referrer}</p>
													{/* <button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#changePasswordModal" onClick={(e) => {
														setActionSuccess(false);
														setSelectedUser(user);
													}}>
														<p className="mb-0"><i className="fa-solid fa-key mr-2"></i>Reset PW</p>
													</button> */}
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{parseFloat(user.cashWallet).toFixed(2)}</p>
													<button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#transactionModal" onClick={(e) => {
														setActionSuccess(false);
														setSelectedUser(user);
														setAmount(0);
													}}>
														<p className="mb-0"><i className="fa-solid fa-pen-to-square mr-2"></i>Manage</p>
													</button>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{parseFloat(user.points).toFixed(2)}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0"><strong>{user.bankName ? user.bankName : "-"}</strong></p>
													<p className="mb-0">{user.accNumber ? user.accNumber : "-"}</p>
													<p className="mb-0">{user.accName ? user.accName : "-"}</p>
												</td>

												<td className="px-3 py-3">
													<p className="mb-0">{user.fullname}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{user.phone}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{user.email}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{Moment(user.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<div className="col-12">
								<h5 className="mt-3 mb-0">User Appointments</h5>
								<p className="mt-2 mb-0">Total {appointmentData && appointmentData.length} Appointments Data</p>
							</div>
							<div className="col-12 mt-3">
								<table className="table table-responsive table-hover table-striped">
									<thead className="thead-dark">
										<tr className="">
											<th className="p-3" scope="col"><p className="mb-0">Appointment</p></th>
											<th className="p-3" scope="col"><p className="mb-0">User</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Device</p></th>
											<th className="p-3" scope="col"><p className="mb-0">PV</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Remark</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Create By</p></th>
										</tr>
									</thead>
									<tbody>
										{appointmentData && appointmentData.map((appointment, index) => (
											<tr key={index}>
												<td className="px-3 py-3">
													<p className="mb-0" style={{fontSize:"14px"}}><strong>ID:</strong> {appointment._id}</p>
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Branch:</strong> {appointment.branch}</p>
													<p className="mb-0" style={{fontSize:"14px"}}><strong>Time:</strong> {Moment(appointment.time).format('YYYY/MM/DD HH:mm')}H</p>
													{appointment.status == "Completed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="profit-green">{appointment.status}</span></strong></p> : <></>}
													{appointment.status == "Booked" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">{appointment.status}</span></strong></p> : <></>}
													{appointment.status == "Cancelled" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="error">{appointment.status}</span></strong></p> : <></>}
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{appointment.username}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0">{appointment.unit}</p>
													{appointment.status == "Booked" ? (
														<button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#transactionModal" onClick={(e) => {
														}}>
															<p className="mb-0" style={{fontSize:"14px"}}>Control</p>
														</button>
													):(
														<></>
													)}
												</td>
												<td className="px-3 py-3">
													<p className="mb-0"><strong>On Hold:</strong> {parseFloat(appointment.points_onhold).toFixed(2)}</p>
													<p className="mb-0"><strong>Spent:</strong> {parseFloat(appointment.points_spent).toFixed(2)}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0" style={{fontSize:"14px"}}>{appointment.remark}</p>
												</td>
												<td className="px-3 py-3">
													<p className="mb-0" style={{fontSize:"14px"}}>{appointment.create_by}</p>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</>
					):(
						<div className="col-12 mt-3">
							<h5>User not found</h5>
							<p>Please enter full username to search for data.</p>
						</div>
					)}
				</div>

			</div>

		</>
  );

};

export default SearchUser;
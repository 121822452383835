import React, { useEffect, useState } from 'react';

import { usePropertyContext } from '../hooks/usePropertyContext';
import { useAuthContext } from '../hooks/useAuthContext';
import { useUpdateProduct } from '../hooks/useUpdateProduct';

const Products = () => {

	const {properties, dispatch} = usePropertyContext();
	const { admin } = useAuthContext();
	const { createNewProduct, createSuccess, setCreateSuccess, updateProductPrice, updateProductImage, error, actionSuccess, setActionSuccess } = useUpdateProduct();

	// Add new product
	const [newProductTitle, setNewProductTitle] = useState('');
	const [newProductImgURL, setNewProductImgURL] = useState('');
	const [newProductPrice, setNewProductPrice] = useState(0);
	const [addNewError, setAddNewError] = useState('');

	const handleAddNewProduct = async () => {
		setCreateSuccess('');
		if (newProductTitle === '') {
			setAddNewError("Product title is empty.");
			return;
		}
		if (newProductImgURL === '') {
			setAddNewError("Product image URL is empty.");
			return;
		}
		if (newProductPrice == 0) {
			setAddNewError("Product price is cannot be zero.");
			return;
		}
		await createNewProduct(admin.token, newProductTitle, newProductPrice, newProductImgURL);
		fetchProducts();
		window.location.reload();
	};


	const [selectedProduct, setSelectedProduct] = useState(null);
	const [newTitle, setNewTitle] = useState('');
	const [newImgURL, setNewImgURL] = useState('');
	const [amount, setAmount] = useState(0);

	const handleUpdatePrice = async (e) => {
		e.preventDefault();
		if (newTitle === '') {
			setNewTitle(selectedProduct.title);
		}
		await updateProductPrice(admin.token, selectedProduct, newTitle, amount);
		fetchProducts();
		setNewTitle('');
	};

	const handleUpdateImg = async (e) => {
		e.preventDefault();
		if (newImgURL === '') {
			return;
		}
		await updateProductImage(admin.token, selectedProduct, newImgURL);
		fetchProducts();
	};

	const fetchProducts = async () => {
		const response = await fetch('https://api.medichip.health/product', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			dispatch({type: 'GET_PROPERTIES', payload: json})
		}
	}

	const toggleProduct = async (_index, _hidden) => {
		const response = await fetch('https://api.medichip.health/product/'+properties[_index]._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${admin.token}`
            },
			body: JSON.stringify({
                hidden: !_hidden,
            })
        })
        const json = await response.json();
		fetchProducts();
	};

	useEffect(() => {
		fetchProducts();
	}, []);

  	return (
		<>
			<div className="modal fade" id="setPriceModal" tabindex="-1" role="dialog" aria-labelledby="setPrice" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Update Product Details</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedProduct !== null ? (
									<form className="w-100" onSubmit={handleUpdatePrice}>
										<h6 className="mb-0">Current Title:</h6>
										<p>{selectedProduct.title}</p>
										<h6 className="mb-0">Current Price:</h6>
										<p>{parseFloat(selectedProduct.price).toFixed(2)}</p>
										<div className="mb-2">
											<label htmlFor="productTitle">
												<strong>New Title:</strong>
											</label>
											<input
											type="text"
											placeholder='Enter product title'
											autoComplete='off'
											name='productTitle'
											className='form-control'
											onChange={(e) => setNewTitle(e.target.value)}
											/>
										</div>
										<div className="mb-2">
											<label htmlFor="amount">
												<strong>New Price:</strong>
											</label>
											<input
											type="number"
											step="0.01"
											placeholder='Enter new product price'
											autoComplete='off'
											name='amount'
											className='form-control'
											onChange={(e) => setAmount(e.target.value)}
											/>
										</div>
										<button type="submit" className="btn setting-button w-100 mt-3">
											<p className="text-white mb-0">Confirm</p>
										</button>
										{/* {error && <div className="error">{error}</div>} */}
									</form>
								):(
									<p className="error mb-0">Failed to fetch product data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Update Success</h5>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>

			<div className="modal fade" id="updateImgModal" tabindex="-1" role="dialog" aria-labelledby="updateImg" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Update Product Image</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedProduct !== null ? (
									<form className="w-100" onSubmit={handleUpdateImg}>
										<h6 className="mb-0">Product Title:</h6>
										<p>{selectedProduct.title}</p>
										{newImgURL && newImgURL !== '' ? (
											<img className="w-100 mb-3" src={newImgURL}></img>
										):(
											<img className="w-100 mb-3" src={selectedProduct.coverImgLink}></img>
										)}
										<div className="mb-2">
											<label htmlFor="productImgLink">
												<strong>New Image URL:</strong>
											</label>
											<input
											type="text"
											placeholder='Enter product title'
											autoComplete='off'
											name='productImgLink'
											className='form-control'
											onChange={(e) => setNewImgURL(e.target.value)}
											/>
										</div>
										<button type="submit" className="btn setting-button w-100 mt-3">
											<p className="text-white mb-0">Update Image</p>
										</button>
									</form>
								):(
									<p className="error mb-0">Failed to fetch product data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Update Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Add New Product</h3>
					</div>
					<div className="col-12 mt-3">
						<div className="page-container">
							<div className="mb-2">
								<label htmlFor="title">
									<strong>Product Title:</strong>
								</label>
								<input
								type="text"
								placeholder='Enter product title'
								autoComplete='off'
								name='title'
								className='form-control'
								onChange={(e) => setNewProductTitle(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="amount">
									<strong>Product Price:</strong>
								</label>
								<input
								type="number"
								step="0.01"
								placeholder='Enter new product price'
								autoComplete='off'
								name='amount'
								className='form-control'
								onChange={(e) => setNewProductPrice(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="productImgLink">
									<strong>Product Image URL:</strong>
								</label>
								<input
								type="text"
								placeholder='Enter product title'
								autoComplete='off'
								name='productImgLink'
								className='form-control'
								onChange={(e) => setNewProductImgURL(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="productImgLink">
									<strong>Product Image Preview:</strong>
								</label>
								<br></br>
								<img className="product-img mb-3" src={newProductImgURL}></img>
							</div>

							<button className="btn setting-button w-100 mt-3" onClick={(e) => {
								handleAddNewProduct();
							}}>
								<p className="text-white mb-0">Add Product</p>
							</button>
							{addNewError && addNewError !== '' ? <p className="error mt-2 mb-0">{addNewError}</p> : <p className="profit-green mt-2 mb-0">{createSuccess}</p>}
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Manage Products</h3>
						<p className="mt-2 mb-0">Total {properties && properties.length} Products</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Visibility</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Product Title</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Image</p></th>
								</tr>
							</thead>
							<tbody>
								{properties && properties.map((product, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className={product.hidden ? "error mb-0" : "profit-green mb-0" }><strong>{product.hidden ? "Hidden" : "Display" }</strong></p>
											<label class="switch">
												<input type="checkbox"
													value={product.hidden ? "" : "checked"}
													checked={!product.hidden}
													onChange={() => toggleProduct(index, product.hidden)}
												></input>
												<span class="slider round"></span>
											</label>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{product.title}</p>
											<p className="mt-2 mb-0"><strong>{parseFloat(product.price).toFixed(2)}</strong></p>
											<button className="btn setting-button unselected-btn mt-2" data-toggle="modal" data-target="#setPriceModal" onClick={(e) => {
												setActionSuccess(false);
												setSelectedProduct(product);
												setNewTitle('');
												setAmount(0);
											}}>
												<p className="mb-0"><i className="fa-solid fa-pen-to-square mr-2"></i>Edit</p>
											</button>
										</td>
										<td className="px-3 py-3">
											<img className="product-img" src={product.coverImgLink}></img>
											<br></br>
											<button className="btn setting-button unselected-btn mt-2" data-toggle="modal" data-target="#updateImgModal" onClick={(e) => {
												setActionSuccess(false);
												setSelectedProduct(product);
											}}>
												<p className="mb-0"><i className="fa-solid fa-image mr-2"></i>Change Image</p>
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);

};

export default Products;
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../hooks/useOrder';
import { useRecharge } from '../hooks/useRecharge';
import { useAuthContext } from '../hooks/useAuthContext';

const Recharges = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();
	const { approveRecharge, rejectRecharge } = useRecharge();

	const [selectedRecharge, setSelectedRecharge] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async (selectedUsername) => {
		const response = await fetch('https://api.medichip.health/user/' + selectedUsername, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setUserData(json);
		console.log("User Data:",json);
	}

	const [rechargeData, setRechargeData] = useState([]);
	const fetchOrdersData = async () => {
		const response = await fetch('https://api.medichip.health/recharge/all', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setRechargeData(json);
	}

	const [submittedTransction, setSubmittedTransction] = useState(false);

	const handleRechargeApproval = async (e) => {
		e.preventDefault();
		setSubmittedTransction(true);
		if (selectedRecharge == null) {
			return;
		} else {
			await approveRecharge(admin.token, selectedRecharge, admin.username, userData[0], "");
		}
		fetchOrdersData();
	};

	const handleRechargeReject = async (e) => {
		e.preventDefault();
		setSubmittedTransction(true);
		if (selectedRecharge == null) {
			return;
		} else {
			await rejectRecharge(admin.token, selectedRecharge, admin.username, "");
		}
		fetchOrdersData();
	};

	useEffect(() => {
		fetchOrdersData();
	}, []);

  	return (
		<>

			{/* Create Transaction */}
			<div className="modal fade" id="approvalModal" tabindex="-1" role="dialog" aria-labelledby="approveRecharge" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Approve / Reject Recharge</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
								setSubmittedTransction(false);
							}}>
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!submittedTransction ? (
							<div className="modal-body">
								{selectedRecharge !== null ? (
									<>
										<p className="mb-2"><strong>Reference ID: </strong><br></br>{selectedRecharge._id}</p>
										<p className="mb-2"><strong>Username: </strong>{selectedRecharge.username}</p>
										<p className="mb-2"><strong>Recharge Amount: <span className="profit-green">+{selectedRecharge.recharge_amount} PV</span></strong></p>
										<form className="w-100" onSubmit={handleRechargeApproval}>
											<button type="submit" className="w-100 btn btn-success mt-3 mr-1">
												<p className="mb-0" style={{fontSize: "12px"}}><i className="fa-solid fa-check mr-1"></i>Approve</p>
											</button>
										</form>
										<form className="w-100" onSubmit={handleRechargeReject}>
											<button type="submit" className="w-100 btn btn-danger mt-3 mr-1">
												<p className="mb-0" style={{fontSize: "12px"}}><i className="fa-solid fa-xmark mr-1"></i>Reject</p>
											</button>
										</form>
									</>
								):(
									<p className="error mb-0">Failed to fetch recharge data. Please refresh page and try again.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Transaction Completed</h5>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Recharges</h3>
						<p className="mt-2 mb-0">Total {rechargeData && rechargeData.length} records</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Recharges</p></th>
									<th className="p-3" scope="col"><p className="mb-0">User</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Recharge PV</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Payment</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Approval</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Remark</p></th>
								</tr>
							</thead>
							<tbody>
							{rechargeData && rechargeData.map((recharge, index) => (
								<tr key={index}>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}><strong>ID:</strong> {recharge._id}</p>
										<p className="mb-0" style={{fontSize:"14px"}}><strong>Time:</strong> {Moment(recharge.time).format('YYYY/MM/DD HH:mm')}H</p>
										{recharge.status == "Completed" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="profit-green">{recharge.status}</span></strong></p> : <></>}
										{recharge.status == "Pending" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="pending">{recharge.status}</span></strong></p> : <></>}
										{recharge.status == "Cancelled" ? <p className="mb-0" style={{fontSize:"14px"}}><strong>Status: <span className="error">{recharge.status}</span></strong></p> : <></>}
									</td>
									<td className="px-3 py-3">
										<p className="mb-0">{recharge.username}</p>
									</td>
									<td className="px-3 py-3">
										<p className="mb-0">{parseFloat(recharge.recharge_amount).toFixed(2)}</p>
									</td>
									<td className="px-3 py-3">
										<p className="mb-0">{recharge.payment_method}</p>
									</td>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}>{recharge.approval != "" ? recharge.approval : <></>}</p>
										{recharge.status == "Pending" ? (
											<>
												<button className="btn action-button unselected-btn" data-toggle="modal" data-target="#approvalModal" onClick={(e) => {
													setSubmittedTransction(false);
													setSelectedRecharge(recharge);
													fetchUserData(recharge.username);
												}}>
													<p className="mb-0" style={{fontSize: "12px"}}>Verify</p>
												</button>
											</>
										):(
											<></>
										)}
									</td>
									<td className="px-3 py-3">
										<p className="mb-0" style={{fontSize:"14px"}}>{recharge.remark != "" ? recharge.remark : "-"}</p>
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Recharges;
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { useAuthContext } from '../hooks/useAuthContext';
import { useWithdrawal } from '../hooks/useWithdrawal';

const Commission = () => {

	const { admin } = useAuthContext();
	const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [adminRemarks, setAdminRemarks] = useState("");

	const [submitted, setSubmitted] = useState(false);

	const { updateWithdrawalStatus, updateRemarks, isLoading, error } = useWithdrawal();

	const adminUpdateStatus = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateWithdrawalStatus(admin.token, admin.username, selectedWithdrawal.username, selectedWithdrawal._id, selectedWithdrawal.amount, statusOption, adminRemarks);
		getAllWithdrawals();
		setSubmitted(true);
	};

	const [allWithdrawals, setAllWithdrawals] = useState();
	const getAllWithdrawals = async () => {
		const response = await fetch('https://api.medichip.health/withdrawal', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setAllWithdrawals(json);
		}
	}

	useEffect(() => {
		getAllWithdrawals();
	}, []);

  	return (
		<>
			<div className="modal fade" id="updateWithdrawalStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateWithdrawalStatus" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Withdrawal Status</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!submitted ? (
							<div className="modal-body">
								{selectedWithdrawal !== null ? (

									<form className="w-100" onSubmit={adminUpdateStatus}>
										<h6 className="mb-0">Username:</h6>
										<p>{selectedWithdrawal.username}</p>
										<h6 className="mb-0">Withdrawal ID:</h6>
										<p>#{selectedWithdrawal.withdrawID}</p>
										<h6 className="mb-0">Withdrawal Amount:</h6>
										<p>{parseFloat(selectedWithdrawal.amount).toFixed(2)}</p>
										<h6 className="mb-0">Current Status:</h6>
										{selectedWithdrawal.status === "Processing" ? <p className="vip-yellow">{selectedWithdrawal.status}</p> : null}
										{selectedWithdrawal.status === "Approved" ? <p className="profit-green">{selectedWithdrawal.status}</p> : null}
										{selectedWithdrawal.status === "Rejected" ? <p className="error">{selectedWithdrawal.status}</p> : null}
										<div className="mb-2">
											<label>
											<strong>Update Status:</strong>
											</label>
											<select 
											className='form-control'
											onChange={(e) => setStatusOption(e.target.value)}
											>
												<option value="">Choose Status</option>
												<option value="Approved">Approved</option>
												<option value="Rejected">Rejected</option>
											</select>
										</div>
										<div className="mb-2">
											<label>
											<strong>Remarks:</strong>
											</label>
											<textarea
											name='review'
											className='w-100 py-3 review-text-field'
											placeholder='Write remark here...'
											onChange={(e) => setAdminRemarks(e.target.value)}
											>
											</textarea>
										</div>
										{statusOption === "Processing" || statusOption === "Approved" || statusOption === "Rejected" ? (
											<button type="submit" className="btn action-button w-100 mt-3">
												<p className="text-white mb-0">Confirm</p>
											</button>
										):(
											<></>
										)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch order data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Withdrawal Status Updated</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Commission</h3>
						<p className="mt-2 mb-0">Total {allWithdrawals && allWithdrawals.length} Records</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Date & Time</p></th>
									<th className="p-3" scope="col"><p className="mb-0">User</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Recharge PV</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Referrer</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Commission</p></th>
								</tr>
							</thead>
							<tbody>
								{allWithdrawals && allWithdrawals.map((review, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(allWithdrawals[index].createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{allWithdrawals[index].username}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>{parseFloat(allWithdrawals[index].amount).toFixed(2)}</strong></p>
											{allWithdrawals[index].status === "Rejected" ? <p className="profit-green mb-0"><strong>Refunded</strong></p> : null}
										</td>
										<td className="px-3 py-3">
											<h6 className="mb-0">{allWithdrawals[index].bankName}</h6>
											<p className="mb-0">{allWithdrawals[index].accNumber}</p>
											<p className="mb-0">{allWithdrawals[index].accName}</p>
										</td>
										<td className="px-3 py-3">
											{allWithdrawals[index].status === "Processing" ? <p className="vip-yellow mb-0"><strong>{allWithdrawals[index].status}</strong></p> : null}
											{allWithdrawals[index].status === "Approved" ? <p className="profit-green mb-0"><strong>{allWithdrawals[index].status}</strong></p> : null}
											{allWithdrawals[index].status === "Rejected" ? <p className="error mb-0"><strong>{allWithdrawals[index].status}</strong></p> : null}
											
											{allWithdrawals[index].status == "Processing" ? (
												<button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#updateWithdrawalStatusModal" onClick={(e) => {
													setSubmitted(false);
													setStatusOption("");
													setSelectedWithdrawal(allWithdrawals[index]);
												}}>
													<p className="mb-0"><i className="fa-solid fa-pen-to-square mr-2"></i>Update</p>
												</button>
											):(
												<></>
											)}
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{allWithdrawals[index].processBy === "" ? "-" : allWithdrawals[index].processBy}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{allWithdrawals[index].remarks === "" ? "-" : allWithdrawals[index].remarks}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Commission;
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

const AuditLogs = () => {

	const { admin } = useAuthContext();

	const [auditLogs, setAuditLogs] = useState();
	const fecthAuditLogs= async () => {
		const response = await fetch('https://api.medichip.health/audit', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setAuditLogs(json);
		}
	}

	useEffect(() => {
		fecthAuditLogs();
	}, []);

  return (
		<>
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Audit Logs</h3>
						<p className="mt-2 mb-0">Total {auditLogs && auditLogs.length} Logs</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Date & Time</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Admin</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Action</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Reference ID</p></th>
								</tr>
							</thead>
							<tbody>
								{auditLogs && auditLogs.map((admin, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(auditLogs[index].createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{auditLogs[index].adminName}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{auditLogs[index].action}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">#{auditLogs[index].refID}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default AuditLogs;
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Admins = () => {

	const { admin } = useAuthContext();

	const [adminData, setAdminData] = useState();
	const fecthAdmins = async () => {
		const response = await fetch('https://api.medichip.health/admin', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setAdminData(json);
		}
	}

	useEffect(() => {
		fecthAdmins();
	}, []);

  return (
		<>
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">System Admins</h3>
						<p className="mt-2 mb-0">Total {adminData && adminData.length} Admins</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Username</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Full Name</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Email</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Registered</p></th>
								</tr>
							</thead>
							<tbody>
								{adminData && adminData.map((admin, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{adminData[index].username}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{adminData[index].fullname}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{adminData[index].email}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(adminData[index].createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
  );

};

export default Admins;